"use client";
import React, { useEffect, useState } from "react";
import { FaArrowUp } from "react-icons/fa";
const GotoTop = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const gotoBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  const listenToScroll = () => {
    let heightToHidden = 250;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHidden) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", listenToScroll);
  }, []);

  return (
    <div className="flex items-center justify-center">
      {isVisible && (
        <div
          onClick={gotoBtn}
          className="text-[14px] w-[2rem] h-[2rem] bg-primary rounded-[50%] fixed
         bottom-[50px] right-[30px] z-[999] flex items-center justify-center cursor-pointer shadow-lg"
        >
          <FaArrowUp className="top-btn-icon" />
        </div>
      )}
    </div>
  );
};

export default GotoTop;

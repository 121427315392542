"use client";
import React, { useState, useEffect } from "react";
import Link from "next/link";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenu,
  NavbarMenuItem,
  NavbarMenuToggle,
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  Avatar,
} from "@nextui-org/react";
import { MdOutlineSpaceDashboard } from "react-icons/md";
import { WoowLogoIcon } from "@/icons/website-icon/woow";
import { AiOutlineUser } from "react-icons/ai";
import { WoowLogoMobile } from "@/icons/website-icon/WoowLogoMobile";
import { usePathname, useRouter } from "next/navigation";
import { NavbarMenuToggleIcon } from "@/icons/website-icon/HomeVectorSvg";
import { getCookie } from "typescript-cookie";
import { useAppSelector } from "@/config/hooks";
import { RootState } from "@/config/store";
const WebsiteHeader = () => {
  const router = useRouter();
  const pathname = usePathname();
  const [navbarBg, setNavbarBg] = useState<boolean>(false);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const rootState = useAppSelector((state: RootState) => state);

  const user = rootState.auth.user;
  console.log(user);
  useEffect(() => {
    if (getCookie("_wid") && user) {
      setIsLoggedIn(true);
    }
  }, [user]);
  const menuItems = [
    {
      key: 1,
      label: "Services",
      route: "/service-and-fees",
    },
    {
      key: 2,
      label: "Track Order",
      route: "/track",
    },
    {
      key: 3,
      label: "About Us",
      route: "/about",
    },
    {
      key: 4,
      label: "Contact Us",
      route: "/contact",
    },
    // {
    //   key: 5,
    //   label: "Resorces",
    //   route: "/resource",
    // },
  ];
  const changeMainNavBackground = () => {
    if (window.scrollY >= 90) {
      setNavbarBg(true);
    } else {
      setNavbarBg(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", changeMainNavBackground);
    return () => {
      window.removeEventListener("scroll", changeMainNavBackground);
    };
  }, []);
  return (
    <>
      {/**************Navbar*****************/}
      <header
        className={`mx-auto py-3 transition duration-500 ${
          navbarBg
            ? "bg-white shadow-lg"
            : "backdrop-blur-sm border-t-[10px] lg:border-[#F6CC46]"
        } hidden sm:block sticky top-0 z-[999]`}
      >
        <div className="w-full container">
          <nav className="max-w-full mx-auto">
            <div className="flex items-center justify-between py-1">
              <div className="cursor-pointer">
                <Link href={"/"}>
                  <WoowLogoIcon />
                </Link>
              </div>
              <ul className="flex items-center gap-6">
                {menuItems?.map((item) => (
                  <li
                    key={item.key}
                    className={`navbar-item relative ${
                      item.route === pathname ? "active-navitem" : ""
                    }`}
                  >
                    <Link
                      href={item.route}
                      className="text-[14px] 2xl:text-[16px] text-black font-medium font-plus_jakarta"
                    >
                      {item.label}
                    </Link>
                  </li>
                ))}

                <li className="text-[14px] text-[#1D1D1F] font-plus_jakarta font-medium">
                  {isLoggedIn ? (
                    <Link href={"/dashboard"}>
                      <Button
                        size="md"
                        variant="bordered"
                        startContent={<MdOutlineSpaceDashboard />}
                        className={`text-[14px] 2xl:text-[16px]
                    font-plus_jakarta border-[12px]
                   bg-[#D1E4FF] ${
                     navbarBg ? "border-none" : "border-none"
                   } rounded-md
                     hover:bg-slate-200`}
                      >
                        Dashboard
                      </Button>
                    </Link>
                  ) : (
                    <Link href={"/login"}>
                      <Button
                        size="md"
                        variant="bordered"
                        startContent={<AiOutlineUser />}
                        className={`text-[14px] 2xl:text-[16px]
                    font-plus_jakarta border-[12px]
                   bg-[#D1E4FF] ${
                     navbarBg ? "border-none" : "border-none"
                   } rounded-md
                     hover:bg-slate-200`}
                      >
                        Login/Register
                      </Button>
                    </Link>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
      {/*********Mobile Menu**********/}
      <div className="sm:hidden sticky top-0 z-[999]">
        {/* <Navbar
          classNames={{
            base: ["sm:hidden"],
          }}
          isBordered
          isMenuOpen={isMenuOpen}
          onMenuOpenChange={setIsMenuOpen}
        >
          <NavbarContent className="sm:hidden" justify="start">
            <NavbarMenuToggle
              aria-label={isMenuOpen ? "Close menu" : "Open menu"}
            />
          </NavbarContent>

          <NavbarContent className="sm:hidden pr-3" justify="center">
            <NavbarBrand>
              <WoowLogoMobile />
            </NavbarBrand>
          </NavbarContent>

          <NavbarContent className="hidden sm:flex gap-4" justify="center">
            <NavbarBrand>
              <WoowLogoMobile />
            </NavbarBrand>
            <NavbarItem>
              <Link color="foreground" href="#">
                Features
              </Link>
            </NavbarItem>
            <NavbarItem isActive>
              <Link href="#" aria-current="page">
                Customers
              </Link>
            </NavbarItem>
            <NavbarItem>
              <Link color="foreground" href="#">
                Integrations
              </Link>
            </NavbarItem>
          </NavbarContent>

          <NavbarContent justify="end">
            <NavbarItem className="hidden lg:flex">
              <Link href="#">Login</Link>
            </NavbarItem>
            <NavbarItem>
              <Button
                size="md"
                variant="bordered"
                startContent={<AiOutlineUser />}
                className="text-[14px]
                 font-plus_jakarta
                bg-white rounded-md border-1
                 border-[#D2D2D2] hover:bg-slate-200"
              >
                Login
              </Button>
            </NavbarItem>
          </NavbarContent>

          <NavbarMenu>
            {menuItems.map((item, index) => (
              <NavbarMenuItem key={`${item}-${index}`}>
                <Link
                  className={`
                  ${ index === 2
                    ? "text-yellow-300"
                    : index === menuItems.length - 1
                    ? "text-red-500"
                    : "text-gray"
                }`}
                  href={item.route}
                 
                >
                  {item.label}
                </Link>
              </NavbarMenuItem>
            ))}
          </NavbarMenu>
        </Navbar> */}
        <Navbar className="bg-transparent">
          <NavbarContent className="sm:hidden pr-3" justify="start">
            <Link href="/">
              <NavbarBrand>
                <WoowLogoMobile />
              </NavbarBrand>
            </Link>
          </NavbarContent>
          <NavbarContent justify="end">
          {isLoggedIn ? (
                    <Link href={"/dashboard"}>
                      <Button
                        size="sm"
                        variant="bordered"
                        startContent={<MdOutlineSpaceDashboard />}
                        className={`text-[14px] 2xl:text-[16px]
                    font-plus_jakarta border-[12px]
                   bg-[#D1E4FF] ${
                     navbarBg ? "border-none" : "border-none"
                   } rounded-md
                     hover:bg-slate-200`}
                      >
                        Dashboard
                      </Button>
                    </Link>
                  ) : (
                    <Link href={"/login"}>
                      <Button
                        size="sm"
                        variant="bordered"
                        startContent={<AiOutlineUser />}
                        className={`text-[14px] 2xl:text-[16px]
                    font-plus_jakarta border-[12px]
                   bg-[#D1E4FF] ${
                     navbarBg ? "border-none" : "border-none"
                   } rounded-md
                     hover:bg-slate-200`}
                      >
                        Login
                      </Button>
                    </Link>
                  )}
            <Dropdown placement="bottom-end">
              <DropdownTrigger>
                {/* <Avatar
                  isBordered
                  as="button"
                  className="transition-transform"
                  color="secondary"
                  name="Jason Hughes"
                  size="sm"
                  src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                /> */}
                <button>
                  <NavbarMenuToggleIcon />
                </button>
              </DropdownTrigger>
              <DropdownMenu aria-label="Profile Actions" variant="flat">
                {menuItems.map((item, index) => (
                  <DropdownItem key={`${item}-${index}`}>
                    <Link
                      className={`
                          ${
                            index === 2
                              ? "text-yellow-300"
                              : index === menuItems.length - 1
                              ? "text-red-500"
                              : "text-gray"
                          }`}
                      href={item.route}
                    >
                      {item.label}
                    </Link>
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>
          </NavbarContent>
        </Navbar>
      </div>
    </>
  );
};

export default WebsiteHeader;
